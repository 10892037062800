import api from './helpers/claraplayer';

const BULB_MIXES = {
  ref: [],
  data: {},
  setRef: function(product, numOfPendants) {
    const found = Object.keys(BULB_MIXES.data).find(ele => {
      return product.includes(ele);
    });
    BULB_MIXES.ref = found ? BULB_MIXES.data[found][numOfPendants] : [];
  },
  initBulbMixPattern: function() {
    const METADATA = api.configuration.getAttribute('Data Source').metadata;
    const dataArr = METADATA['Bulb Mix'];
    dataArr.forEach(eleArr => {
      const [productInfo, bulbType] = eleArr[0]
        .split('-')
        .map(str => str.trim());
      const regex = /\d+ Pendants$/;
      const product = productInfo.split(regex)[0].trim();
      const numOfPendants = productInfo.match(regex)[0].match(/\d+/)[0];
      if (!BULB_MIXES.data[product]) {
        BULB_MIXES.data[product] = {};
      }
      if (!BULB_MIXES.data[product][numOfPendants]) {
        BULB_MIXES.data[product][numOfPendants] = {};
      }
      BULB_MIXES.data[product][numOfPendants][bulbType] = eleArr.slice(1);
    });
  },
};

export { BULB_MIXES };

import { Model } from '../Model';
import api from './claraplayer';
import fetch from 'node-fetch';

const SPREADSHEET_ID = '1T3r5ZgmNnLl_Z13GyzWmvzyqkg9jziXpmKHTnbLS7Z8';

async function leadTime(values) {
return new Promise(function(resolve, reject) {
  let leadTime = 2;
  var options = {
       url: 'https://spreadsheets.google.com/feeds/worksheets/'+SPREADSHEET_ID +'/public/basic?alt=json'
   }
  const spreadSheetData = fetch(options.url, {
     method: 'GET',
   }).then((resp) => resp.json()) // Transform the data into json
  .then(function(data) {
    data.feed.entry.forEach(function(worksheet) {
      const title = worksheet.title
      if (title['$t'] == Model.Product){
        let sheeturl = worksheet.link[0]["href"]+"?alt=json";
        sheeturl = sheeturl.replace("basic", "values");
        const workSheetData = fetch(sheeturl, {
           method: 'GET',
         }).then((resp) => resp.json()) // Transform the data into json
        .then(function(data) {
          if (typeof data.feed.entry !== 'undefined' && data.feed.entry ){
            data.feed.entry.forEach(function(row) {
              if (Model[row['gsx$type']['$t']] == row['gsx$part']['$t']){
                if (parseInt(row['gsx$leadtime']['$t']) > leadTime){
                  leadTime=parseInt(row['gsx$leadtime']['$t']);
                }
              }
            });

          }
          resolve(leadTime);
        }).catch((error) => {
          reject("failed");
        });
      }
    });

  }).catch((error) => {
    reject("failed");
  });

});

}



export {leadTime};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt){
    if (txt!= "x"){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }else{
      return txt;
    }
    });
}

function getUrlParams() {
  const queryParams = new URLSearchParams(window.location.search);
  const isInternal = queryParams.get('isInternal') === 'true';
  const pinned =
    window.location.origin.substr(-3, 3) === 'dev' ||
    queryParams.get('pinned') === 'false'
      ? false
      : true;
  const initialConfig = JSON.parse(
    queryParams.get('configurator.configuration')
  );

  if (!initialConfig || !initialConfig.Product) {

    var script_tag = document.getElementById('threekitMain');
 
      if(script_tag){
        if(script_tag.getAttribute("data-object")){

        
              
                var objectString = decodeURI(script_tag.getAttribute("data-object")).replace(/\+/g, ' ').replace(/%2C/g,",").replace(/[\[\]']+/g,'').replace(/\"/g, "").replace(/cageshade/g,"Cage / Shade");
               var objectArr = objectString.split(",");
               var finalObject = {};
               
               var arrayLength = objectArr.length;
              for (var i = 0; i < arrayLength; i++) {
                 var optionArr = objectArr[i].split('-');
                 if(optionArr.length>2){
                  var attrVal = toTitleCase(optionArr.slice(2).join('-').trim().replace(/\\\\\\/g, '"'));
                 }
                 else {
                    var attrVal = toTitleCase(optionArr[2]).replace(/\\\\\\/g, '"');
                 }
                 if (optionArr[1] == "nop"){
                   var attr = "Number of Pendants";
                 }
                 else {
                   var attr = toTitleCase(optionArr[1]);
                   attr = attr.replace('feet','Feet');
                   attrVal=attrVal.replace('Xl','XL');
                   attrVal=attrVal.replace('Ufo','UFO');
                 }
                 finalObject[attr] = attrVal.replace('Led','LED');
                

                }

               return {
                initialConfig: finalObject,
                isInternal,
                pinned,
              };
            }
            else{
              const product = 'Cluster Chandelier - Even';
              return {
                initialConfig: { Product: product, 'Number of Pendants': 3,'Ceiling Plate':'Black','Bulb':'LED 5\" Globe' },
                isInternal,
                pinned,
              };
      }}
      else{
    const product = 'Cluster Chandelier - Even';
    return {
      initialConfig: { Product: product, 'Number of Pendants': 3,'Ceiling Plate':'Black','Bulb':'LED 5\" Globe','Cage / Shade': 'None' },
      isInternal,
      pinned,
    };
  }
  }
  return { initialConfig, isInternal, pinned };
}

const urlParams = getUrlParams();

export default urlParams;

import urlParams from './helpers/urlParams';
import { Model } from './Model';
import { View } from './View';
import { BULB_MIXES } from './metadata';
import api from './helpers/claraplayer';
import {
  NODE_IDS,
  ATTRNAME_NUMBER_PENDANTS,
  ATTR_CREATE_ONCE,
  ATTR_EXCLUDED,
  PENDANT_ATTRIBUTES,
  SWITCH_ATTRIBUTES,
  LENGTH_ATTRIBUTES,
} from './constants';

const { isInternal } = urlParams;

const Controllor = {
  handler: async function(attr, value) {
    Model[attr.name] = value;
    const nameArr = attr.name.split('-');
    if (isInternal && nameArr.length === 2) {
      await View.update[nameArr[0].trim()](nameArr[1].trim() - 1, value);
    } else {
      await View.update[attr.name](attr, value);
    }
     //console.log(attr.name + ' set to new value: ' + value);
  },
  /**
   * Based on 3 resources: config, existing Model, Data source
   * @description Reset model data based on selected product
   * @param {string} product Name of Product
   */
  resetModel: function(product) {
    console.log('Reseting Model for ' + product);
    const newModel = {};
    const METADATA = api.configuration.getAttribute('Data Source').metadata;
    const attributeSet = METADATA[product];
    const config = api.configuration.getConfiguration({ all: true });
    for (let i = 0; i < attributeSet.length; i++) {
      newModel[attributeSet[i][0]] = attributeSet[i].find(function(val) {
        return (
          (config[attributeSet[i][0]] &&
            val === config[attributeSet[i][0]].toString()) ||
          (Model[attributeSet[i][0]] &&
            val === Model[attributeSet[i][0]].toString())
        );
      })
        ? config[attributeSet[i][0]]
          ? config[attributeSet[i][0]]
          : Model[attributeSet[i][0]]
        : attributeSet[i][1];
    }
    Object.keys(SWITCH_ATTRIBUTES).forEach(function(key) {
      if (product.indexOf('Cluster') !== -1 && key === 'Cage / Shade') return;
      if (product.indexOf('Sconce') !== -1 && key === 'Cage / Shade') return;
      newModel[key] =
        Model[key] != undefined ? Model[key] : SWITCH_ATTRIBUTES[key][0];
    });
    if (newModel[ATTRNAME_NUMBER_PENDANTS])
      newModel[ATTRNAME_NUMBER_PENDANTS] *= 1;
    const numOfPendants = newModel[ATTRNAME_NUMBER_PENDANTS] || 1;
    if (isInternal) {
      PENDANT_ATTRIBUTES.concat(
        Object.keys(SWITCH_ATTRIBUTES).concat(LENGTH_ATTRIBUTES)
      ).forEach(attr => {
        for (let i = 0; i < numOfPendants; i++) {
          if (newModel[attr]) {
            const name = attr + ` - ${i + 1}`;
            newModel[name] =
              config[name] != undefined
                ? config[name]
                : Model[name] != undefined
                ? Model[name]
                : newModel[attr];
          }
        }
      });
    }
    newModel['Product'] = product;
    Object.keys(Model).forEach(key => {
      delete Model[key];
    });
    Object.keys(newModel).forEach(key => {
      Model[key] = newModel[key];
    });
    // console.log(newModel);
    BULB_MIXES.setRef(product, numOfPendants);
    // Model = Object.assign({}, newModel);
    return Model;
  },
  updatePendantsModel: function(value) {
    const current = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
    value = value * 1;
    if (!isNaN(value)) {
      if (isInternal) {
        const config = api.configuration.getConfiguration({ all: true });
        const metadata = api.configuration.getAttribute('Data Source').metadata[
          Model['Product']
        ];
        PENDANT_ATTRIBUTES.concat(
          Object.keys(SWITCH_ATTRIBUTES).concat(LENGTH_ATTRIBUTES)
        ).forEach(attr => {
          if (
            Model['Product'].indexOf('Cluster') !== -1 &&
            attr === 'Cage / Shade'
          )
            return;
            if (
              Model['Product'].indexOf('Sconce') !== -1 &&
              attr === 'Cage / Shade'
            )
              return;
          const attrData =
            metadata[
              metadata.findIndex(ele => {
                return ele[0] === attr;
              })
            ];
          const defaultValue = attrData
            ? attrData[1]
            : SWITCH_ATTRIBUTES[attr]
            ? SWITCH_ATTRIBUTES[attr][0]
            : null;
          if (defaultValue) {
            if (value > current) {
              for (let i = current; i < value; i++) {
                const name = attr + ' - ' + (i + 1);
                Model[name] = config[name] || defaultValue;
              }
            } else {
              for (let i = value; i < current; i++) {
                const name = attr + ' - ' + (i + 1);
                delete Model[name];
              }
            }
          }
        });
      }
    }
    Model[ATTRNAME_NUMBER_PENDANTS] = value;
    // console.log(Model);
    return Model;
  },
  resetConfigurator: async function(product) {
     console.log('Reseting configurator for ' + product);
    //delete forms
    const forms = api.configuration.getForms();
    if (forms) {
      await Promise.all(
        forms.map(form => {
          if (form.name !== 'Main') {
            return api.configuration._.deleteForm(form.id);
          }
        })
      );
    }
    //delete attributes
    const attrs = api.configuration.getAttributes();
    if (attrs) {
      Promise.all(
        attrs.map(function(attr, index) {
          if (
            ATTR_EXCLUDED.indexOf(attr.name) !== -1 ||
            attr.name === 'Product'
          ) {
            return Promise.resolve();
          } else {
            return api.configuration._.deleteAttribute(attr.id);
          }
        })
      );
    }
    //add attributes
    const productAttributes = api.configuration.getAttribute('Data Source')
      .metadata[product];
    ////build the set of all attributes
    let attributeSet = productAttributes.reduce(function(set, attr, idx) {
      const attrName = attr[0];
      set[attrName] = { index: idx, values: [], valueActions: {} };
      for (let i = 1; i < attr.length; i++) {
        const value = isNaN(attr[i]) ? attr[i] : attr[i] * 1;
        set[attrName].values.push(value);
        set[attrName].valueActions[value] = [];
      }
      return set;
    }, {});
    const maxNumOfPendants = attributeSet[ATTRNAME_NUMBER_PENDANTS]
      ? Math.max(...attributeSet[ATTRNAME_NUMBER_PENDANTS].values)
      : 1;
    attributeSet = Object.keys(SWITCH_ATTRIBUTES).reduce(function(set, attr) {
      if (product.includes('Cluster') && attr === 'Cage / Shade') return set;
      if (product.includes('Sconce') && attr === 'Cage / Shade') return set;
      set[attr] = { values: SWITCH_ATTRIBUTES[attr], valueActions: {} };
      for (const value of set[attr].values) {
        set[attr].valueActions[value] = [];
      }
      return set;
    }, attributeSet);
    ////add attributes to configurator
    await Promise.all(
      Object.keys(attributeSet).reduce(function(seq, key) {
        let name = '';
        if (isInternal) {
          //Internal version
          if (ATTR_CREATE_ONCE.indexOf(key) !== -1) {
            name = key;
            seq.push(Controllor.addAttribute(attributeSet[key], name));
          } else {
            //Pendant attribute
            for (let i = 0; i <= maxNumOfPendants; i++) {
              if (i === 0) {
                name = key;
              } else {
                name = `${key} - ${i}`;
              }
              seq.push(Controllor.addAttribute(attributeSet[key], name));
            }
          }
        } else {
          //Client version
          name = key;
          seq.push(Controllor.addAttribute(attributeSet[key], name));
        }
        return seq;
      }, [])
    ).then(this.setSwitchValueActions(maxNumOfPendants));
    //add number of pendants attribute
    if (attributeSet[ATTRNAME_NUMBER_PENDANTS]) {
      const attr = api.configuration.getAttribute(ATTRNAME_NUMBER_PENDANTS);
      if (attr) {
        await api.configuration._.deleteAttribute(attr.id);
      }
      const defaultValue =
        Model[ATTRNAME_NUMBER_PENDANTS] &&
        attributeSet[ATTRNAME_NUMBER_PENDANTS].values.indexOf(
          Model[ATTRNAME_NUMBER_PENDANTS]
        ) !== -1
          ? Model[ATTRNAME_NUMBER_PENDANTS]
          : attributeSet[ATTRNAME_NUMBER_PENDANTS].values[0];
      await api.configuration._.addAttribute({
        type: 'Options',
        name: ATTRNAME_NUMBER_PENDANTS,
        values: attributeSet[ATTRNAME_NUMBER_PENDANTS].values,
        valueActions: {},
        defaultValue: defaultValue,
      });
      await api.configuration.setConfiguration({
        'Number of Pendants': defaultValue,
      });
    }

    await this.buildForm(maxNumOfPendants);

    // console.log('Finished reseting configurator for ' + product);
  },
  buildForm: async function(numOfPendants) {
    // build new form
    let steps = [];
    let formId = await this.setForm('Major');
    steps.push({ title: 'Major', description: '', formId });
    if (isInternal) {
      for (let i = 1; i <= numOfPendants; i++) {
        const formName = `Pendants - ${i}`;
        formId = await this.setForm(formName, i);
        steps.push({ title: formName, description: '', formId });
      }
    } else {
      const formName = `Pendants`;
      formId = await this.setForm(formName);
      steps.push({ title: formName, description: '', formId });
    }

    // set Main form as tabs contains all other forms
    if (!api.configuration.getForm('Main')) {
      await api.configuration._.addForm({ name: 'Main' });
    }
    formId = api.configuration.getForm('Main').id;
    await api.configuration._.setForm(formId, {
      steps,
      meta: { displayAs: 'tabs' },
      fields: [],
    });
    if (Model[ATTRNAME_NUMBER_PENDANTS])
      await this.hideUnusedTabs(Model[ATTRNAME_NUMBER_PENDANTS], steps);
  },
  hideUnusedTabs: async function(num, steps) {
    const formId = api.configuration.getForm('Main').id;
    let newSteps = steps
      .map(step => {
        const nameArr = step.title.split('-');
        if (nameArr.length === 2) {
          const number = nameArr[1].trim() * 1;
          if (number > num) {
            return null;
          } else {
            return step;
          }
        }
        return step;
      })
      .filter(obj => !!obj);
    await api.configuration._.setForm(formId, {
      steps: newSteps,
      meta: { displayAs: 'tabs' },
      fields: [],
    });
    Object.keys(SWITCH_ATTRIBUTES).forEach(attr => {
      if (isInternal) {
        for (let i = 1; i <= Model[ATTRNAME_NUMBER_PENDANTS]; i++) {
          const name = `${attr} - ${i}`;
          if (Model[name])
            api.configuration.executeAttribute(name, Model[name]);
        }
      }
    });
  },
  setSwitchValueActions: function(count) {
    const allAttributes = api.configuration.getAttributes();
    const cordAtts = {};
    const cageShadeAtts = {};
    const shadeFitterIds = {};
    const ceilingHookIds = {};
    let ceilingHookValues;
    for (const attr of allAttributes) {
      if (
        attr.name.indexOf('Cord Color') !== -1 ||
        attr.name.indexOf('Thick Cord Type') !== -1
      ) {
        const index = attr.name;
        cordAtts[index] = attr.id;
      }
      if (
        (attr.name.indexOf('Cage') !== -1 ||
          attr.name.indexOf('Shade') !== -1) &&
        attr.name.indexOf('Cage / Shade') === -1
      ) {
        const index = attr.name;
        cageShadeAtts[index] = attr.id;
      }
      if (attr.name.indexOf('Shade Fitter') !== -1) {
        shadeFitterIds[attr.name] = attr.id;
      }
      if (attr.name.indexOf('Ceiling Hooks') !== -1) {
        ceilingHookIds[attr.name] = attr.id;
        if (!ceilingHookValues) ceilingHookValues = attr.values;
      }
    }
    for (const attr of allAttributes) {
      if (
        attr.name.indexOf('Cord Type') === 0 &&
        attr.valueActions['Twisted'].length === 0
      ) {
        const nameArray = attr.name.split('-');
        const twistedIndex =
          nameArray.length === 2
            ? 'Twisted Cord Color - ' + nameArray[1].trim()
            : 'Twisted Cord Color';
        const roundIndex =
          nameArray.length === 2
            ? 'Round Cord Color - ' + nameArray[1].trim()
            : 'Round Cord Color';
        const thickIndex =
          nameArray.length === 2
            ? 'Thick Cord Type - ' + nameArray[1].trim()
            : 'Thick Cord Type';
        const ceilingHookIndex =
          nameArray.length === 2
            ? 'Ceiling Hooks - ' + nameArray[1].trim()
            : 'Ceiling Hooks';
        attr.valueActions['Twisted'].push({
          action: 'set-form-field-visibility',
          tags: cordAtts[roundIndex],
          value: false,
        });
        attr.valueActions['Twisted'].push({
          action: 'set-form-field-visibility',
          tags: cordAtts[thickIndex],
          value: false,
        });
        attr.valueActions['Twisted'].push({
          action: 'set-form-field-visibility',
          tags: cordAtts[twistedIndex],
          value: true,
        });
        attr.valueActions['Round'].push({
          action: 'set-form-field-visibility',
          tags: cordAtts[twistedIndex],
          value: false,
        });
        attr.valueActions['Round'].push({
          action: 'set-form-field-visibility',
          tags: cordAtts[thickIndex],
          value: false,
        });
        attr.valueActions['Round'].push({
          action: 'set-form-field-visibility',
          tags: cordAtts[roundIndex],
          value: true,
        });
        attr.valueActions['Thick'].push({
          action: 'set-form-field-visibility',
          tags: cordAtts[twistedIndex],
          value: false,
        });
        attr.valueActions['Thick'].push({
          action: 'set-form-field-visibility',
          tags: cordAtts[roundIndex],
          value: false,
        });
        attr.valueActions['Thick'].push({
          action: 'set-form-field-visibility',
          tags: cordAtts[thickIndex],
          value: true,
        });
        if (ceilingHookIds[ceilingHookIndex]) {
          const nonThickSet = ceilingHookValues.reduce((acc, val) => {
            acc[val] = true;
            return acc;
          }, {});
          const thickSet = ceilingHookValues.reduce((acc, val) => {
            acc[val] = val.indexOf('Silicone') === -1;
            return acc;
          }, {});
          attr.valueActions['Round'].push({
            action: 'set-attribute-options-visibility',
            tags: ceilingHookIds[ceilingHookIndex],
            value: nonThickSet,
          });
          attr.valueActions['Twisted'].push({
            action: 'set-attribute-options-visibility',
            tags: ceilingHookIds[ceilingHookIndex],
            value: nonThickSet,
          });
          attr.valueActions['Thick'].push({
            action: 'set-attribute-options-visibility',
            tags: ceilingHookIds[ceilingHookIndex],
            value: thickSet,
          });
        }
      }

      if (attr.name.indexOf('Cage / Shade') !== -1) {
        const nameArray = attr.name.split('-');
        const cageIndex =
          nameArray.length === 2 ? 'Cage - ' + nameArray[1].trim() : 'Cage';
        const shadeIndex =
          nameArray.length === 2 ? 'Shade - ' + nameArray[1].trim() : 'Shade';
        const shadeFitterIndex =
          nameArray.length === 2
            ? 'Shade Fitter - ' + nameArray[1].trim()
            : 'Shade Fitter';
        attr.valueActions['Cage'].push({
          action: 'set-form-field-visibility',
          tags: cageShadeAtts[shadeIndex],
          value: false,
        });
        attr.valueActions['Cage'].push({
          action: 'set-form-field-visibility',
          tags: cageShadeAtts[cageIndex],
          value: true,
        });
        attr.valueActions['Cage'].push({
          action: 'set-form-field-visibility',
          tags: shadeFitterIds[shadeFitterIndex],
          value: false,
        });
        attr.valueActions['Shade'].push({
          action: 'set-form-field-visibility',
          tags: cageShadeAtts[cageIndex],
          value: false,
        });
        attr.valueActions['Shade'].push({
          action: 'set-form-field-visibility',
          tags: cageShadeAtts[shadeIndex],
          value: true,
        });
        attr.valueActions['Shade'].push({
          action: 'set-form-field-visibility',
          tags: shadeFitterIds[shadeFitterIndex],
          value: true,
        });
        attr.valueActions['None'].push({
          action: 'set-form-field-visibility',
          tags: cageShadeAtts[cageIndex],
          value: false,
        });
        attr.valueActions['None'].push({
          action: 'set-form-field-visibility',
          tags: cageShadeAtts[shadeIndex],
          value: false,
        });
        attr.valueActions['None'].push({
          action: 'set-form-field-visibility',
          tags: shadeFitterIds[shadeFitterIndex],
          value: false,
        });
      }
    }
    //todo
    if (count > 0 && isInternal) {
      const l = Math.min(count, Model[ATTRNAME_NUMBER_PENDANTS]);
      for (let i = 1; i <= l; i++) {
        if (Model['Product'].indexOf('Cluster') === -1 && Model['Product'].indexOf('Sconce') === -1 ) {
          api.configuration.executeAttribute(
            'Cage / Shade - ' + i,
            Model['Cage / Shade - ' + i] || Model['Cage / Shade'] || 'None'
          );
        }
        api.configuration.executeAttribute(
          'Cord Type - ' + i,
          Model['Cord Type - ' + i] || Model['Cord Type'] || 'Round'
        );
      }
    } else {
      if (Model['Product'].indexOf('Cluster') === -1 && Model['Product'].indexOf('Sconce') === -1) {
        api.configuration.executeAttribute(
          'Cage / Shade',
          Model['Cage / Shade'] || 'None'
        );
      }
      api.configuration.executeAttribute(
        'Cord Type',
        Model['Cord Type'] || 'Round'
      );
    }
  },
  addAttribute: async function(set, name) {
    let attr = api.configuration.getAttribute(name);
    const defaultValue = Model[name] || set.values[0];
    if (!attr) {
      if (name !== ATTRNAME_NUMBER_PENDANTS) {
        await api.configuration._.addAttribute({
          type: 'Options',
          name: name,
          actions: set.actions ? set.actions.slice() : [],
          values: set.values.slice(),
          valueActions: JSON.parse(JSON.stringify(set.valueActions)),
          defaultValue: defaultValue,
        });
      }
    } else {
      await api.configuration.setAttribute(name, {
        actions: set.actions ? set.actions : [],
        values: set.values,
        valueActions: set.valueActions,
        defaultValue: defaultValue,
      });
    }
  },
  setForm: async function(formName, i = 1) {
    if (!api.configuration.getForm(formName)) {
      await api.configuration._.addForm({ name: formName });
    }
    const formId = api.configuration.getForm(formName).id;
    const fields = api.configuration
      .getAttributes()
      .map(attr => {
        const nameArr = attr.name.split('-');
        // when formName doesn't contain '-' , means this attr is for whole product instead of each pendant
        if (ATTR_EXCLUDED.indexOf(attr.name) !== -1) return null;
        if (formName.split('-').length === 1) {
          if (
            nameArr.length === 1 &&
            ((formName === 'Major' &&
              ATTR_CREATE_ONCE.indexOf(nameArr[0]) !== -1) ||
              (formName === 'Pendants' &&
                ATTR_CREATE_ONCE.indexOf(nameArr[0]) === -1))
          ) {
            return {
              attribute: attr,
              attributeId: attr.id,
              bom: undefined,
              displayAs: undefined,
              metadata: undefined,
            };
          } else return null;
        } else {
          // when formName  contain '-' , means this attr is for each pendant
          if (nameArr.length === 2) {
            const number = nameArr[1].trim() * 1;
            if (number === i) {
              return {
                attribute: attr,
                attributeId: attr.id,
                bom: undefined,
                displayAs: undefined,
                metadata: undefined,
              };
            } else return null;
          } else if (nameArr.length === 1) {
          } else return null;
        }
      })
      .filter(item => !!item);

    await api.configuration._.setForm(formId, {
      fields,
    });
    return formId;
  },
};

export { Controllor };

import { Model } from '../Model';
import api from './claraplayer';



function getImages(imageList) {

  var params = {
   Bucket: 'install-photos',
   Delimiter: '/',
   Prefix: ''
  }



  var myScores = [];
  var myProducts = [];








     var SKU_CODES = {
      Product: {
        'Wood Chandelier - 18" Square': 'w1',
        'Wood Chandelier - 18" Round': 'w2',
        'Wood Chandelier - 35" x 9"': 'w5',
        'Wood Chandelier - 35" x 12"': 'w6',
        'Wood Chandelier - 47" x 9"': 'w8',
        'Wood Chandelier - 47" x 12"': 'w9',
        'Wood Chandelier - 24" Round': 'w3',
        'Wood Chandelier - 24" Square': 'w4',
        'Wood Chandelier - 70" x 12"': 'wb',
        'Wood Chandelier - 35" x 18"': 'w7',
        'Wood Chandelier - 47" x 24"': 'wa',
        'Cluster Chandelier - Even': 'ce',
        'Cluster Chandelier - Grape': 'cg',
        'Cluster Chandelier - Staggered': 'cs',
        'Wrap Chandelier': 'ub',
        'Single Pendant': 'sp',
        'Swag Chandelier': 'sw',
        'Metal Chandelier - 12" Round': 'm1',
        'Metal Chandelier - 16" Round': 'mc',
        'Metal Chandelier - 18" Round': 'm2',
        'Metal Chandelier - 18" Square': 'm3',
        'Metal Chandelier - 24" Round': 'm4',
        'Metal Chandelier - 24" Square': 'm5',
        'Metal Chandelier - 33" Round': 'm6',
        'Metal Chandelier - 36" Square': 'm7',
        'Metal Chandelier - 48" Square': 'm8',
        'Metal Chandelier - 35" x 12"': 'm9',
        'Metal Chandelier - 47" x 12"': 'ma',
        'Metal Chandelier - 70" x 12"': 'mb',
        'Flush Mount': 'fm',
        'Beam Wrap - 4 Feet': 'b4',
        'Torch Wall Sconce': 'z1',
        'Hinge Wall Sconce': 'z2',
        'Modular Chandelier - 48" Square':'mz',
        'Modular Chandelier - 36" x 96"':'my',
      },
      'Number of Pendants': {
        1: 1,
        2: 2,
        3: 3,
        5: 5,
        7: 7,
        9: 9,
        10: 10,
        14: 14,
        16:16,
        17: 17,
        19: 19,
        18: 18,
        25:25,
        27: 27,
      },
      'Ceiling Plate': {
        Black: 'bl',
        Graphite: 'gr',
        Bronze: 'bz',
        Brass: 'bs',
        Antique: 'an',
        Nickel: 'ni',
        Chrome: 'cr',
        Copper: 'cp',
        White: 'wh',
        'Wood Ebony': 'we',
        'Wood Grey': 'wg',
        'Wood Walnut': 'wt',
        'Wood White': 'ww',
        'Wood Natural': 'wn',
        'Plug-in': 'pg',
      },
      'Metal Base': {
        Black: 1,
        Nickel: 2,
        Copper: 3,
        Brass: 4,
        White: 5,
      },
    'Base Color': {
      Black: 1,
      White:5,
    },
      'Wood Type': {
        New: 1,
        Reclaimed: 2,
      },
      'Wood Stain': {
        Ebony: 1,
        Grey: 2,
        Walnut: 3,
        White: 4,
        Natural: 5,
      },
      'Chain Finish': {
        Brass: 1,
        Black: 2,
        Nickel: 3,
      },
      'Bracket Finish': {
        Black: 1,
        Nickel: 2,
      },
      'Hardware Finish': {
        White: 1,
        Copper: 2,
        Black: 3,
        Brass: 4,
      },
      'Cluster Ring': {
        White: 1,
        Copper: 2,
        Black: 3,
        Brass: 4,
        Nickel: 5,
      },
      'Arm Finish': {
        White: 1,
        Copper: 2,
        Black: 3,
        Brass: 4,
        Nickel: 5,
      },
      'Hinge Finish': {
        White: 1,
        Copper: 2,
        Black: 3,
        Brass: 4,
        Nickel: 5,
      },
      'Tee Joint Finish': {
        White: 1,
        Copper: 2,
        Black: 3,
        Brass: 4,
        Nickel: 5,
      },
      'Endcap Finish': {
        White: 1,
        Copper: 2,
        Black: 3,
        Brass: 4,
        Nickel: 5,
      },
      'Ceiling Hooks': {
        'Black Silicone': 1,
        'White Silicone': 2,
        'Black Hook': 3,
        'White Hook': 4,
        'Copper Hook': 5,
        'Nickel Hook': 6,
        'Brass Hook': 7,
        'Black Metal': 8,
        'White Metal': 9,
        'Copper Metal': 'a',
        'Nickel Metal': 'b',
        'Brass Metal': 'c',
        'Antique Metal': 'd',
        'Black Thick': 'e',
        'White Thick': 'f',
        'Black Standard': 'g',
        'White Standard': 'h',
      },
      'Cord Type': {
        Round: 'r',
        Twisted: 't',
        Thick: 'h',
        Chain: 'c',
      },
      'Cord Color': {
        Black: 10,
        Brown: 11,
        Berry: 12,
        Purple: 13,
        Navy: 14,
        Teal: 15,
        Graphite: 16,
        Rust: 17,
        Wine: 18,
        Mauve: 19,
        Cobalt: 20,
        Pine: 21,
        Grey: 22,
        'Terracotta Tweed': 23,
        Red: 24,
        Pink: 25,
        Olive: 26,
        White: 27,
        Gold: 28,
        Orange: 29,
        Bubblegum: 30,
        Turquoise: 31,
        Green: 32,
        'Grey Dot': 33,
        Tan: 34,
        Yellow: 35,
        Coral: 36,
        Mint: 37,
        Lime: 38,
        'White Brass': 39,
        Beige: 40,
        Champagne: 41,
        Blush: 42,
        Eucalyptus: 43,
        'Grey Tweed': 44,
        Chevron: 45,
        Rainbow: 46,
        Magenta: 47,
        'Green Tweed': 48,
        'Manila Rope': 49,
        'Cotton Rope': 50,
        'Taupe':51,
        '1 Inch Black Chain': 52,
        '1 Inch Brass Chain': 53,
        '4 Inch Black Chain': 54,
        '4 Inch Gold Chain': 55,
        '4 Inch Slate Chain': 56,
        '4 Inch White Chain': 57,
        '4 Inch Crimson Chain': 58,
        '4 Inch Evergreen Chain': 59,
        '4 Inch Blue Chain': 60,
        '1 Inch Nickel Chain': 61,
        '1 Inch Antique Chain': 62,
      },
      'Cage / Shade': {
        None: 'n',
        Cage: 'c',
        Shade: 's',
      },
      'Shades and Cages': {
        'Glass Globe': 10,
        'Amber Glass Globe': 11,
        'Glass Cylinder': 12,
        'Glass Dome': 13,
        'Glass Cone': 14,
        'Glass Bell': 15,
        'Copper Cone': 16,
        'Antique Cone': 17,
        'Black Cone': 18,
        'Nickel Cone': 19,
        'Black Ruffle': 20,
        'Metal Net': 21,
        'Black Large Flat': 22,
        'Black Diamond': 23,
        'Brass Diamond': 24,
        'Copper Diamond': 25,
        'Chrome Diamond': 26,
        'Mint Diamond': 27,
        'White Diamond': 28,
        'Black Round': 29,
        'Black Hinge': 30,
        'Brass Hinge': 31,
        'Steel Round': 32,
        'Tube Cage': 33,
        'Flare Cage': 34,
        'Radio Cage': 35,
        'Brass Tube Cage':36,
        'White Cone':37,
        'Brass Cone':38,
        'Black 5" Drum':39,
        'Nickel 5" Drum':40,
        'Copper 5" Drum':41,
        'Brass 5" Drum':42,
        'White 5" Drum':43,
        'Black 12" Drum':44,
        'Nickel 12" Drum':45,
        'Copper 12" Drum':46,
        'Brass 12" Drum':47,
        'White 12" Drum':48,
        'Black 16" Industrial':49,
        'Nickel 16" Industrial':50,
        'Copper 16" Industrial':51,
        'Brass 16" Industrial':52,
        'White 16" Industrial':53,
        'Grey Felt Cone':54,
        'Khaki Felt 10" Dome':55,
        'Grey Felt 20" Flat':56,
        'Grey Felt 16" Dome':57,
        'Black Small Flat':58,
        'Glass Small Flat':59,
        'Frosted Glass Cone':60,
        'Black 5" Dome':61,
        'Nickel 5" Dome':62,
        'Copper 5" Dome':63,
        'Brass 5" Dome':64,
        'White 5" Dome':65,
        'Black 12" Dome':66,
        'Black 18" Dome':67,
        'Black 24" Dome':68,
         'Brass 18" Dome': 69,
        'Brass 24" Dome': 70,
        'White 18" Dome': 71,
        'White 24" Dome': 72,
        'Glass 10" Neckless':73,
        'White 10" Neckless':74,
        'Glass 12" Neckless':75,
        'White 12" Neckless':76,
        'Glass 14" Neckless':77,
        'White 14" Neckless':78,
        'Glass 16" Neckless':79,
        'White 16" Neckless':80,
        'Black 24" Disk':81,
        'White 24" Disk':82,
        'Black 5" Disk':83,
        'Brass 5" Disk':84,
        'Black 8" Disk':85,
        'Brass 8" Disk':86,
        'White 8" Disk':87,
        'Black 12" Disk':88,
        'Brass 12" Disk':89,
        'Brass 24" Disk':90,
        'Black Octagon Cage':91,
        'Brass Octagon Cage':92,
        'Black 20" Deep Dome':93,
        'White 20" Deep Dome':94,
        'Grey 20" Deep Dome':95,
        'Bamboo 18" Woven Dome':96,
        'Bamboo 18" Woven Basket':97,
        'Bamboo 18" Woven Round':98,
        'Bamboo 24" Woven Dome':99,
        'Bamboo 24" Woven Basket': 'a1',
        'Bamboo 30" Woven Basket': 'a2',
        'Bamboo 36" Woven Basket': 'a3',
        'Bamboo 24" Woven Round': 'a4',
        'Glass 8" Neckless': 'a5',
        'White 8" Neckless': 'a6',
        'Black/Brass 24" Dome': 'a7',
      },  
      'Shade Cover': {
        Black: 1,
        Brass:2,
        Nickel: 3,
        Copper: 4,
        White: 5,
        Antique:6,
      },
      'Shade Fitter': {
        Antique:1,
        Nickel:2,
        Black:3,
        White:4,
        Bronze:5,
        Copper:6,
        Chrome:7,
        Graphite:8,
        Brass:9,
      },
      Socket: {
        'Modern Black': 10,
        'Modern Steel': 11,
        'Modern Nickel': 12,
        'Modern Copper': 13,
        'Modern Gold': 14,
        'Modern Brass': 15,
        'Modern Antique': 16,
        'Modern White': 17,
        'Vintage Black': 18,
        'Vintage Bronze': 19,
        'Vintage Graphite': 20,
        'Vintage Chrome': 21,
        'Vintage Nickel': 22,
        'Vintage Copper': 23,
        'Vintage Brass': 24,
        'Vintage Antique': 25,
        'Vintage White': 26,
        'Keyed Black': 27,
        'Keyed Bronze': 28,
        'Keyed Graphite': 29,
        'Keyed Chrome': 30,
        'Keyed Nickel': 31,
        'Keyed Copper': 32,
        'Keyed Brass': 33,
        'Keyed Antique': 34,
        'Keyed White': 35,
        'Threaded Black': 36,
        'Threaded Bronze': 37,
        'Threaded Graphite': 38,
        'Threaded Chrome': 39,
        'Threaded Nickel': 40,
        'Threaded Copper': 41,
        'Threaded Antique': 42,
        'Threaded White': 43,
        'Threaded Brass': 44,
        'Wood Ebony': 45,
        'Wood Walnut': 46,
        'Wood Grey': 47,
        'Wood White Wash': 48,
        'Wood Natural': 49,
        'Modern Threaded Black': 50,
        'Modern Threaded Brass': 51,
        'Modern Threaded Copper': 52,
        'Modern Threaded Nickel': 53,
        'Modern Threaded White': 54,
        'Modern Threaded Marble': 55,
      },
      Bulb: {
        'None': 10,
        'None': 11,
        'None': 12,
        'None': 13,
        'None': 14,
        'None': 15,
        'None': 16,
        'LED 7" Tube': 17,
        'LED Edison': 18,
        'LED Victorian': 19,
        'LED Radio': 20,
        'LED 3" Globe': 21,
        'LED 5" Globe': 22,
        'LED 3" Clear Globe -3500k': 23,
        'LED 5" Clear Globe -3500k': 24,
        'LED 5" Milk White Globe': 25,
        'LED Milk White Edison': 26,
        'LED Milk White 4" Tube': 27,
        'None': 28,
        'None': 29,
        'None': 30,
        'None': 31,
        'LED Diamond': 32,
        'Dipped Copper 5" LED Globe': 33,
        'Dipped Gold 5" LED Globe': 34,
        'Dipped Chrome 5" LED Globe': 35,
        'None': 36,
        'LED Amber Bulb Mix': 37,
        'None': 38,
        'LED Smoke Edison': 39,
        'LED Smoke 7" Tube': 40,
        'LED Smoke 3" Globe': 41,
        'LED Smoke 5" Globe': 42,
        'Dipped Black 5" LED Globe': 43,
        'LED Smoke Uneven 5" Globe':44,
        'LED Uneven 5" Globe':45,
        'LED Amber XL Beehive':46,
        'LED Amber XL Uneven Pear':47,
        'LED Amber XL Pear':48,
        'LED Amber XL 11" Tube':49,
        'LED Amber XL 8" Globe':50,
        'LED Amber XL Oval':51,
        'LED Amber XL Turret':52,
        'LED Amber XL Teardrop':53,
        'LED Amber XL Mushroom':54,
        'LED Amber XL Uneven Bottle':55,
        'LED Amber XL Gourd':56,
        'LED Smoke XL Uneven Pear':57,
        'LED Smoke XL UFO':58,
        'LED Smoke XL Bubbles':59,
        'LED Smoke XL Bottle':60,
        'LED Smoke XL Lined Teardrop':61,
        'LED Smoke XL Antler':62,
        'LED Amber XL Bulb Mix':63,
        'LED Smoke XL Bulb Mix':64,
        'LED White Diamond':65,
        'LED Clear Edison -3500k':66,
        'LED White 2" Globe':67,
        'LED White 3" Globe':68,
        'LED 3" Clear Globe -2700k': 69,
        'LED 5" Clear Globe -2700k': 70,
        'LED Clear Edison -2700k':71,
        'LED 2" Clear Globe -2700k':72,
        'LED Amber XL Antler': 73,
        'LED White XL 8" Globe': 74,
        'LED Clear XL 8" Globe -2700k': 75,
        'LED White Bulb Mix': 76,
        'LED Clear Globe Bulb Mix': 77,
         None: '',
      },
         Length: {
        '1': 10,
        '2': 11,
        '3': 12,
        '4': 13,
        '5': 14,
        '6': 15,
        '7': 16,
        '8': 17,
        '9': 18,
        '10': 19,
        '11': 20,
        '12': 21,
        '13': 22,
        '14': 23,
        '15': 24,
        '16': 25,
        '17': 26,
        '18': 27,
        '19': 28,
        '20': 29,
        '21': 30,
        '24': 31,
        '27': 32,
        '30': 33,
        '33': 34,
        '36': 35,
        '39': 36,
        '42': 37,
        '45': 38,
        '48': 39,
        '51': 40,
        '54': 41,
        '57': 42,
        '60': 43,
        '63': 44,
        '66': 45,
        '69': 46,
        '72': 47,
        '4-6 Feet Staggered': 48,
        '6 Feet': 49,
        '6-10 Feet Staggered': 50,
        '10 Feet': 51,
        '10-15 Feet Staggered': 52,
        '15 Feet': 53,
        '20-35 Staggered': 54,
        '20-48 Staggered': 55,
        '20-72 Staggered': 56,
        Staggered: 54,
        '84': 57,
        '96': 58,
        '108': 59,
        '120': 60,
        '180': 61,
        '240': 62,
        '20-72 Step':63,
        '20-72 Diagonal Step':64,
        '20-72 Diamond':65,
        '36-120 Staggered': 66,
        '36-180 Staggered': 67,
      },
      'Socket Stem': {
        'None':'',
          'Brass - 3"':10,
          'White - 3"':11,
          'Nickel - 3"':12,
          'Copper - 3"':13,
          'Black - 3"':14,
          'Brass - 5"':15,
          'White - 5"':16,
          'Nickel - 5"':17,
          'Copper - 5"':18,
          'Black - 5"':19,
          'Brass - 7"':20,
          'White - 7"':21,
          'Nickel - 7"':22,
          'Copper - 7"':23,
          'Black - 7"':24,
        },
    };


    var currentConfiguration = api.configuration.getConfiguration();

     for (let i = 0; i < imageList.length; i++) {
      //initializing my product for each image
      var myProduct= {
        Product:"",
        "Number of Pendants":"",
        "Ceiling Plate":"",
        "Metal Base":"",
        "Base Color":"",
        "Wood Type":"",
        "Wood Stain":"",
        "Chain Finish":"",
         "Arm Finish":"",
         "Hinge Finish":"",
         "Tee Joint Finish":"",
         "Endcap Finish":"",
        "Bracket Finish":"",
        "Hardware Finish":"",
        "Cluster Ring":"",
        "Ceiling Hooks":"",
        "Cord Type":"",
        "Round Cord Color":"",
        "Twisted Cord Color":"",
        "Thick Cord Type":"",
        "Cage / Shade":"",
        "Shade":"",
        "Cage":"",
        "Shade Fitter":"",
        "Socket":"",
        "Bulb":"None",
        "Socket Stem":"None",
      };
     var myCode = imageList[i].substr(imageList[i].indexOf('-') + 1).split('.')[0];

     var codeSections=myCode.split("-");

     //Add product/nop/cp

     myProduct["Product"] = getKeyByValue(SKU_CODES["Product"],codeSections[0].substring(0,2));

     myProduct["Number of Pendants"] = getKeyByValue(SKU_CODES["Number of Pendants"],codeSections[1].replace(/(^\d+)(.+$)/i,'$1'));
     if ( codeSections[1].replace(/[0-9]/g, '') != 'mp'){
       myProduct["Ceiling Plate"] = getKeyByValue(SKU_CODES["Ceiling Plate"],codeSections[1].replace(/[0-9]/g, ''));
     }
     var last = codeSections[codeSections.length - 1];
     var length = "";
     if(codeSections.length == 4){
     var length = last.substring(0, 2);
     if(last.length > 2){
       myProduct["Socket Stem"] = getKeyByValue(SKU_CODES["Socket Stem"],last.substring(2,4));

     }
   }

     //Add the wood stain / hardware section then remove from code sections AND assign length
     switch(myProduct["Product"]){
         case "Single Pendant" :
                    if (length.length == 2){
             myProduct["Length(Feet)"] = getKeyByValue(SKU_CODES["Length"],length);
           }
           break;

         case "Beam Wrap - 4 Feet":
               case "Wrap Chandelier":
                    myProduct["Wood Stain"] = getKeyByValue(SKU_CODES["Wood Stain"],codeSections[2].substring(0,1));

           myProduct["Chain Finish"] = getKeyByValue(SKU_CODES["Chain Finish"],codeSections[2].substring(1,2));

         myProduct["Bracket Finish"] = getKeyByValue(SKU_CODES["Bracket Finish"],codeSections[2].substring(2,3));

          codeSections.splice(2,1)

         break;
         case "Hinge Wall Sconce":
                    myProduct["Arm Finish"] = getKeyByValue(SKU_CODES["Arm Finish"],codeSections[2].substring(0,1));
           myProduct["Hinge Finish"] = getKeyByValue(SKU_CODES["Hinge Finish"],codeSections[2].substring(1,2));
          codeSections.splice(2,1)
         break;
              case "Torch Wall Sconce":
                    myProduct["Arm Finish"] = getKeyByValue(SKU_CODES["Arm Finish"],codeSections[2].substring(0,1));
           myProduct["Tee Joint Finish"] = getKeyByValue(SKU_CODES["Tee Joint Finish"],codeSections[2].substring(1,2));
          myProduct["Endcap Finish"] = getKeyByValue(SKU_CODES["Endcap Finish"],codeSections[2].substring(2,3));
          codeSections.splice(2,1)
         break;
         case 'Wood Chandelier - 18" Round':
         case 'Wood Chandelier - 18" Square':
         case 'Wood Chandelier - 24" Round':
         case 'Wood Chandelier - 24" Square':
         case 'Wood Chandelier - 35" x 9"':
         case 'Wood Chandelier - 35" x 12"':
         case 'Wood Chandelier - 35" x 18"':
         case 'Wood Chandelier - 47" x 9"':
         case 'Wood Chandelier - 47" x 12"':
         case 'Wood Chandelier - 47" x 24"':
         case 'Wood Chandelier - 70" x 12"':
           myProduct["Wood Type"] = getKeyByValue(SKU_CODES["Wood Type"],codeSections[2].substring(0,1));
           myProduct["Wood Stain"] = getKeyByValue(SKU_CODES["Wood Stain"],codeSections[2].substring(1,2));
           codeSections.splice(2,1);
           if (length.length == 2){
             myProduct["Length"] = getKeyByValue(SKU_CODES["Length"],length);
           }
           break;
           case 'Metal Chandelier - 12" Round':
          case 'Metal Chandelier - 16" Round':
         case 'Metal Chandelier - 18" Square':
         case 'Metal Chandelier - 18" Round':
         case 'Metal Chandelier - 24" Square':
         case 'Metal Chandelier - 24" Round':
         case 'Metal Chandelier - 36" Round':
         case 'Metal Chandelier - 36" Square':
         case 'Metal Chandelier - 48" Square':
         case 'Metal Chandelier - 35" x 12"':
         case 'Metal Chandelier - 47" x 12"':
         case 'Metal Chandelier - 70" x 12"':
           myProduct["Metal Base"] = getKeyByValue(SKU_CODES["Metal Base"],codeSections[2]);
           codeSections.splice(2,1);
                  if (length.length == 2){
             myProduct["Length"] = getKeyByValue(SKU_CODES["Length"],length);
           }
           break;
           case 'Modular Chandelier - 48" Square':
           case 'Modular Chandelier - 36" x 96"':
           myProduct["Base Color"] = getKeyByValue(SKU_CODES["Base Color"],codeSections[2]);
           codeSections.splice(2,1);
                  if (length.length == 2){
             myProduct["Length"] = getKeyByValue(SKU_CODES["Length"],length);
           }
           break;
         case "Swag Chandelier":
           myProduct["Ceiling Hooks"] = getKeyByValue(SKU_CODES["Ceiling Hooks"],codeSections[2]);
           codeSections.splice(2,1);
                    if (length.length == 2){
             myProduct["Length"] = getKeyByValue(SKU_CODES["Length"],length);
           }
           break;
         case "Cluster Chandelier - Grape":
           myProduct["Cluster Ring"] = getKeyByValue(SKU_CODES["Cluster Ring"],codeSections[2]);
          codeSections.splice(2,1)
         case "Cluster Chandelier - Staggered" :
         case "Cluster Chandelier - Even":
                    if (length.length == 2){
             myProduct["Fixture Length"] = getKeyByValue(SKU_CODES["Length"],length);
           }
           break;
         default:
         break;
       }
  //Add Cord Type and color
     if (myProduct["Product"] != 'Flush Mount'){
     myProduct["Cord Type"] = getKeyByValue(SKU_CODES["Cord Type"],codeSections[2].substring(0,1));

     if(myProduct["Cord Type"] == "Thick"){
        myProduct["Thick Cord Type"] = getKeyByValue(SKU_CODES["Cord Color"],codeSections[2].substring(1,3));
     }
     else if (myProduct["Cord Type"] == "Round"){

       myProduct["Round Cord Color"] = getKeyByValue(SKU_CODES["Cord Color"],codeSections[2].substring(1,3));
     }
       else if (myProduct["Cord Type"] == "Twisted"){

       myProduct["Twisted Cord Color"] = getKeyByValue(SKU_CODES["Cord Color"],codeSections[2].substring(1,3));
     }
      else if (myProduct['Cord Type'] == "Chain"){
         let chainInfo = getKeyByValue(SKU_CODES["Cord Color"],codeSections[2].substring(1,3));
         myProduct["Chain Size"] = chainInfo.split(' ').slice(0,2).join(' ');
         myProduct["Chain Color"] = chainInfo.split(' ').slice(2,3).join('');
      }
     }
  //Add Cage/Shade and shade fitter
      myProduct["Cage / Shade"] = getKeyByValue(SKU_CODES["Cage / Shade"],codeSections[2].substring(3,4));

     switch (myProduct["Cage / Shade"]){
       case "None":
         var finalString = codeSections[2].substring(4);
         break;
       case "Cage":
         myProduct["Cage"] = getKeyByValue(SKU_CODES["Shades and Cages"],codeSections[2].substring(4,6));
         var finalString = codeSections[2].substring(6);
         break;
         case "Shade":
         myProduct["Shade"] = getKeyByValue(SKU_CODES["Shades and Cages"],codeSections[2].substring(4,6));
         if(myProduct["Shade"].includes("Neckless")){
          myProduct["Shade Cover"] = getKeyByValue(SKU_CODES["Shade Cover"],codeSections[2].substring(6,7));
         
          finalString = codeSections[2].substring(7);
        }
        else{
          finalString = codeSections[2].substring(6);
        }
         break;
       default:

         break;

     }
     //if the single digit shade fitter is in the final string then assign shade fitter
     if(isOdd(finalString.length)){
       //console.log(finalString);
       myProduct["Shade Fitter"] = getKeyByValue(SKU_CODES["Shade Fitter"],finalString.substring(0,1));
       finalString = finalString.substring(1);

     }
     //Ass Socket and Bulb
     myProduct["Socket"] = getKeyByValue(SKU_CODES["Socket"],finalString.substring(0,2));
     finalString = finalString.substring(2);
     if(finalString.length > 0){
       myProduct["Bulb"] = getKeyByValue(SKU_CODES["Bulb"],finalString.substring(0,2));

     }


       //console.log(myProduct);
       //console.log(currentConfiguration);
         var thisScore = 0
     Object.keys(currentConfiguration).forEach(function(key) {
     switch(key){
       case 'Product':
         if (currentConfiguration[key] == myProduct[key]) {
           thisScore += 10;
         }
         else if(currentConfiguration[key].includes("Wood") && myProduct[key].includes("Wood")){
           thisScore += 6;
           if(currentConfiguration['Wood Stain'] == myProduct['Wood Stain'])
           {
             thisScore +=2;
           }

         }
         else if(currentConfiguration[key].includes("Metal") && myProduct[key].includes("Metal")){
           thisScore += 5;
           if(currentConfiguration['Metal Base'] == myProduct['Metal Base'])
           {
             thisScore +=2;
           }

         }
         else if(currentConfiguration[key].includes("Cluster") && myProduct[key].includes("Cluster")){
           thisScore += 6;
         }
         else if(currentConfiguration[key].includes("Sconce") && myProduct[key].includes("Sconce")){
           thisScore += 7;
         }
         break;
         case 'Number of Pendants':
         if (currentConfiguration[key] == myProduct[key]) {
           thisScore += 1;
         }
         break;
          case 'Cage / Shade':
         if (currentConfiguration[key] == myProduct[key]) {
           thisScore += 3;
           if(currentConfiguration[key] == 'Shade'){

             if (currentConfiguration['Shade'] == myProduct['Shade']){
               thisScore += 7;
             }
             else if(currentConfiguration['Shade'].includes("Glass") && myProduct['Shade'].includes("Glass")){
               thisScore += 4;
             }
                else if(currentConfiguration['Shade'].includes("Felt") && myProduct['Shade'].includes("Felt")){
               thisScore += 4;
             }
              else if(currentConfiguration['Shade'].includes("Dome") && myProduct['Shade'].includes("Dome")){

               thisScore += 4;
             }
             else if(currentConfiguration['Shade'].includes("Cone") && myProduct['Shade'].includes("Cone")){
               thisScore += 4;
             }
             else if(currentConfiguration['Shade'].includes("Drum") && myProduct['Shade'].includes("Drum")){
              thisScore += 4;
            }
            else if(currentConfiguration['Shade'].includes("Flat") && myProduct['Shade'].includes("Flat")){
              thisScore += 4;
            }
            else if(currentConfiguration['Shade'].includes("Flat") && myProduct['Shade'].includes("Flat")){
              thisScore += 4;
            }
             else if(currentConfiguration['Shade'].includes("Bamboo") && myProduct['Shade'].includes("Bamboo")){
               thisScore += 4;
             }
           }
           if(currentConfiguration[key] == 'Cage'){
             if (currentConfiguration['Cage'] == myProduct['Cage']){
               thisScore += 6
             }
             else if(currentConfiguration['Cage'].includes("Diamond") && myProduct['Cage'].includes("Diamond")){
               thisScore += 4;
             }
           }
         }
         break;

          case 'Cord Type':
         //console.log("current " +currentConfiguration[key] + " and the product " + myProduct[key]);
         if(myProduct[key]=='Chain'){
          thisScore+=2;
         }
         if (currentConfiguration[key] == myProduct[key]) {

           if(currentConfiguration[key] == "Thick"){


             thisScore += 10;
              if(currentConfiguration["Thick Cord Type"] == myProduct["Thick Cord Type"]){
               thisScore += 1;
             }
           }
           else if(currentConfiguration[key] == "Twisted"){

             thisScore += 1;
              if(currentConfiguration["Twisted Cord Color"] == myProduct["Twisted Cord Color"]){
               thisScore += 1;
             }
           }
           else if(currentConfiguration[key] == "Chain"){

            thisScore += 10;
             if(currentConfiguration["Chain Color"] == myProduct["Chain Color"]){
              thisScore += 1;
            }
            if(currentConfiguration["Chain Size"] == myProduct["Chain Size"]){
              thisScore += 1;
            }
          }
           else if(currentConfiguration[key] == "Round"){

             thisScore += 1;
             if(currentConfiguration["Round Cord Color"] == myProduct["Round Cord Color"]){

               thisScore += 1;
             }
           }
         }
         break;
          case 'Bulb':
         if (currentConfiguration[key] == myProduct[key]) {
           thisScore += 3;
         }
         else if(currentConfiguration['Bulb'].includes("Smoke") && myProduct['Bulb'].includes("Smoke")){
           thisScore += 4;
         }
         else if(currentConfiguration['Bulb'].includes("XL") && myProduct['Bulb'].includes("XL")){
           thisScore += 4;
         }
         else if(currentConfiguration['Bulb'].includes("White") && myProduct['Bulb'].includes("White")){
           thisScore += 2;
         }
         else if(currentConfiguration['Bulb'].includes("Clear") && myProduct['Bulb'].includes("Clear")){
           thisScore += 1;
         }
         else if(currentConfiguration['Bulb'].includes("Amber") && myProduct['Bulb'].includes("Amber")){
           thisScore += 1;
         }
         else if(currentConfiguration['Bulb'].includes("Dipped") && myProduct['Bulb'].includes("Dipped")){
           thisScore += 4;
         }

         break;
         case 'Socket':
         if (currentConfiguration[key] == myProduct[key]) {
           thisScore += 2;
           if(currentConfiguration['Socket'].includes("Modern") && myProduct['Socket'].includes("Modern")){
            thisScore += 2;
          }
         }
         else if(currentConfiguration['Socket'].includes("Modern Threaded") && myProduct['Socket'].includes("Modern Threaded")){
           thisScore += 1;
         }
         else if(currentConfiguration['Socket'].includes("Modern") && myProduct['Socket'].includes("Modern")){
           thisScore += 1;
         }
         else if(currentConfiguration['Socket'].includes("Keyed") && myProduct['Socket'].includes("Keyed")){
           thisScore += 1;
         }
         else if(currentConfiguration['Socket'].includes("Vintage") && myProduct['Socket'].includes("Vintage")){
           thisScore += 1;
         }
         else if(currentConfiguration['Socket'].includes("Modern") && myProduct['Socket'].includes("Modern")){
           thisScore += 1;
         }
         else if(currentConfiguration['Socket'].includes("Wood") && myProduct['Socket'].includes("Wood")){
          thisScore += 4;
        }
         else if (currentConfiguration['Socket'].split(" ")[currentConfiguration['Socket'].length-1] == myProduct['Socket'].split(" ")[myProduct['Socket'].length-1]) {
           thisScore += 1;
         }
         break;
         case 'Ceiling Plate':
         if (currentConfiguration[key] == myProduct[key]) {
           thisScore += 1;
         }
         break;

     };



   });

      myScores.push(thisScore);
      myProducts.push((Object.assign({}, myProduct)));
   }


  const getTopN = (arr, n = 12) => {
    const _arr = arr.map((value, index) => [value, index]);
    // by using b[0] - a[0] instead of a[0] - b[0] we can get the array in non-increasing order
    _arr.sort((a, b) => b[0] - a[0])
    return _arr.slice(0, n).map(([_, index]) => index);
  }

  var finalImages = {};
  var topScores = getTopN(myScores);

  for (let i = 0; i < topScores.length; i++) {
    let index = i;
    let thisproduct = myProducts[topScores[i]];
    let url = "https://install-photos.s3.amazonaws.com/" + imageList[topScores[i]];

    finalImages[index] = {
      url: url,
      // Add other variables as needed. For example:
      score: myScores[topScores[i]],
      // variableName: variableValue
      product:thisproduct,
    };
  }
// Convert the finalImages object to an array
const finalImagesArray = Object.values(finalImages);

// Sort the finalImagesArray based on the score in descending order
finalImagesArray.sort((a, b) => b.score - a.score);
const finalImagesSorted = {};

for (let i = 0; i < finalImagesArray.length; i++) {
  finalImagesSorted[i] = finalImagesArray[i];
}

return finalImagesSorted;





function isOdd(x) { return x & 1; };
function getKeyByValue(object, value) {
  if (isNaN(value)){
return Object.keys(object).find(key => object[key] === value);
  }
  else {
    return Object.keys(object).find(key => object[key] === Number(value));
  }
};
}



export { getImages };

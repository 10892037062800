import { Model } from '../Model';
import api from './claraplayer';



async function cloneFixture(attachPoint, attachNode, cloneName) {
     

     const productSize = 'Modular - ' + Model['Product']
     .split('-')[1]
     .trim()
     .replace(/["]+/g, '');

     const attachNull = api.scene.find({
     from: { name: productSize },
     name: 'Attach' + attachPoint,
   });
api.sceneio.attachNewNull
var newClonedModel = await api.scene.clone(
  {
    from: { id: api.scene.find({ name: 'Modular Chandelier' }) },
    type: ['PolyMesh', 'Null'],
  },
{withNull: cloneName}
);
console.log(newClonedModel);
var newClonedModel = await api.scene.clone(
  {
    from: { id: api.scene.find({ name: 'pendantNull0' }) },
    type: ['PolyMesh', 'Null'],
  },
{withNull: cloneName}
);
const newModel = api.scene.find({ from: api.sceneIO.getSceneId(), name: cloneName });

const attachNewNull = api.scene.find({
     from: { name: productSize, parent: newModel },
     name: 'Attach' + attachNode,
   });

console.log(attachNull, attachNewNull, newModel);
console.log(attachPoint, attachNode, cloneName);
   api.scene.attachModel(
    attachNull,
    newModel,
    attachNewNull,
    false
  );

}



export { cloneFixture };

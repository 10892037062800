import { Model } from '../Model';
import api from '../helpers/claraplayer';
import {
  Colors,
  CEILING_PLATE_COLORS,
  ATTRNAME_NUMBER_PENDANTS,
} from '../constants';
import {
  setNodeMaterial,
  setNodeVisibility,
  setVisibility,
  setMaterialBaseColor,
  setMaterialReflection,
  setRotation,
} from '../helpers/sceneHelpers';

function updateCeilingPlate() {

  const woodStainStart = Model['Wood Stain'];
  const metalBase = Model['Metal Base'];
  const value = Model['Ceiling Plate'] ? Model['Ceiling Plate'] : woodStainStart ? 'Wood ' + woodStainStart :  metalBase ? Model['Metal Base'] : Model['Ceiling Plate'];
  setVisibility(api.scene.find('Ceiling Plates'), false, 'all');
  setVisibility(api.scene.find('Plug-in'), false, 'all');
  let nodeName;
  console.log('NOP is ' + Model['Number of Pendants']);
  if (Model['Product'].indexOf('Wood') !== -1 || Model['Product'].indexOf('Beam Wrap') !== -1) {
    nodeName = `Ceiling Plate_1 Hole`;
  } 
  else if (Model['Product'].indexOf('Wall Sconce') !== -1) {
    nodeName = `Ceiling Plate_Wall 1 Hole`;
  }
  else if (Model['Product'].indexOf('Modular') == -1 && (Model['Product'].indexOf('Swag') || Model['Product'].indexOf('Cluster')) && Model['Number of Pendants'] >= 9  && (Model['Cord Type'] == 'Thick' || Model['Cord Type'] == 'Chain') ){
    const NOP = Model['Number of Pendants'];
    nodeName = `12Ceiling Plate_${NOP} Hole`;
  }
  else {
    if (value === 'Plug-in') {
      setVisibility(api.scene.find('Plug-in'), true, 'all');
      return;
    }
    else if (metalBase || Model['Product'].indexOf('Modular') !== -1) {
      return;
    }
    const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
    const wood =
      value.indexOf('Wood') !== -1 && numOfPendants > 5 ? 'Wood' : '';
    nodeName = `${wood}Ceiling Plate_${numOfPendants} Hole`;
  }
  const nodeId = api.scene.find(nodeName);
  const woodStain = value.replace('Wood', '').trim();
  let matId;
  if (woodStain !== value) {
    matId = api.scene.find(`M_New_${woodStain}`);
  } else {
    matId =
      value === 'White'
        ? api.scene.find('M_CeilingWhite')
        : (value === 'Antique')
        ? api.scene.find('M_CeilingA')
        : api.scene.find('M_Ceiling Plate');
    const color = CEILING_PLATE_COLORS[value]
      ? CEILING_PLATE_COLORS[value]
      : Colors[value];
    setMaterialBaseColor(matId, color);
  }
  //Nuts
  const ceilingNutsId = api.scene.find({
    from: nodeId,
    name: 'Ceiling Plate_Nuts',
  });
  if (ceilingNutsId) {
    setNodeVisibility(ceilingNutsId, true);
    if (value === 'White') {
      setNodeMaterial(ceilingNutsId, api.scene.find('M_CeilingWhite'));
    }else if (value === 'Antique') {
      setNodeMaterial(ceilingNutsId, api.scene.find('M_CeilingA'));
    }
    else {
      if (value.indexOf('Wood') !== -1) {

        if (value.indexOf('Natural') !== -1 || value.indexOf('White') !== -1) {
          setNodeMaterial(ceilingNutsId, api.scene.find('M_CeilingWhite'));
          setMaterialBaseColor(api.scene.find('M_CeilingWhite'), CEILING_PLATE_COLORS['White']);
        } else {
          setNodeMaterial(ceilingNutsId, api.scene.find('M_Grip'));
          setMaterialBaseColor(api.scene.find('M_Grip'), Colors['Black']);
        }
      } else {
        setNodeMaterial(ceilingNutsId, api.scene.find('M_Ceiling Plate'));
      }
    }
  }


  setNodeVisibility(nodeId, true);
  setNodeMaterial(nodeId, matId);
}


function updateWallSconce() {
  api.scene.setAll(
  {
    from: { name: 'Wall Sconces' },
    type: ['PolyMesh'],
    plug: 'Properties',
    property: 'visible',
  },
  false
);

if (Model['Product'].indexOf('Wall Sconce') !== -1){

  if (Model['Product'].indexOf('Hinge') !== -1 ){
setVisibility(api.scene.find(`Hinge Wall Sconce`), true, 'all');

  const armFinish = Model['Arm Finish'];
  const hingeFinish = Model['Hinge Finish'];
  const matArmName = `M_SconceArm`;
  const matJointName = `M_SconceJoint`;

  const matArmId = api.scene.find(matArmName);
  const matJointId = api.scene.find(matJointName);
  setMaterialBaseColor(matArmId, Colors[armFinish]);
  if(armFinish === 'White' || armFinish ==='Black'){
    setMaterialReflection(matArmId,.8,.2);
  }
  else {
    setMaterialReflection(matArmId,.2,1);
  }
  setMaterialBaseColor(matJointId, Colors[hingeFinish]);
  if(hingeFinish === 'White' || hingeFinish ==='Black'){
    setMaterialReflection(matJointId,.8,.2);
  }
  else {
    setMaterialReflection(matJointId,.2,1);
  }
  }
  else if (Model['Product'].indexOf('Torch') !== -1 ){
    setVisibility(api.scene.find('Torch Wall Sconce'), true, 'all');
      const armFinish = Model['Arm Finish'];
      const teeJointFinish = Model['Tee Joint Finish'];
      const endcapFinish = Model['Endcap Finish'];
      const matArmName = 'M_SconceArm';
      const matTeeJointName = 'M_SconceJoint';
      const matEndcapName = 'M_SconceEndcap';
    
      const matArmId = api.scene.find(matArmName);
      const matTeeJointId = api.scene.find(matTeeJointName);
      const matEndcapId = api.scene.find(matEndcapName);
      setMaterialBaseColor(matArmId, Colors[armFinish]);
      if(armFinish === 'White' || armFinish ==='Black'){
        setMaterialReflection(matArmId,.8,.2);
      }
      else {
        setMaterialReflection(matArmId,.2,1);
      }
      setMaterialBaseColor(matTeeJointId, Colors[teeJointFinish]);
      if(teeJointFinish === 'White' || teeJointFinish ==='Black'){
        setMaterialReflection(matTeeJointId,.8,.2);
      }
      else {
        setMaterialReflection(matTeeJointId,.2,1);
      }
      setMaterialBaseColor(matEndcapId, Colors[endcapFinish]);
      if(endcapFinish === 'White' || endcapFinish ==='Black'){
        setMaterialReflection(matEndcapId,.8,.2);
      }
      else {
        setMaterialReflection(matEndcapId,.2,1);
      }
      }
}

}


function updateWoodChandelier() {
  if (Model['Product'].indexOf('Wood') !== -1) {
    const woodType = (Model['Wood Type'] && Model['Wood Type']) || 'New';
    const woodStain = Model['Wood Stain'];
    const matName = `M_${woodType}_${woodStain}`;
    const matId = api.scene.find(matName);
    const productSize = Model['Product']
      .split('-')[1]
      .trim()
      .replace(/["]+/g, '');
    const productNodeId = api.scene.find({ name: productSize });
    const ceilingWiresId = api.scene.find({
      from: productNodeId,
      name: 'Ceiling Wires',
    });
    const outgoingWireId = api.scene.find('Outgoing Wire');
    setNodeVisibility(outgoingWireId, true);
    setMaterialBaseColor(
      api.scene.find('M_Outgoing Wire'),
      Colors[Model['Ceiling Plate']]
    );
    setNodeVisibility(ceilingWiresId, true);
    setVisibility(ceilingWiresId, true, 'all');
    setNodeVisibility(productNodeId, true);
    setNodeMaterial(productNodeId, matId);
  } else if (Model['Product'].indexOf('Metal Chandelier') !== -1) {
    const metalBaseColor = Model['Metal Base'];
    let matName = `M_Ceiling Plate`;
    if ( metalBaseColor == 'Black'){
       matName = `M_Ceiling PlateBW`;
    }
    else if (metalBaseColor == 'White'){
      matName = `M_MetalBaseW`;
    }
    const matId = api.scene.find(matName);
    const productSize = Model['Product']
      .split('-')[1]
      .trim()
      .replace(/["]+/g, '');
  const productName = 'Metal - ' + productSize;
    const productNodeId = api.scene.find({ name: productName });
    setNodeVisibility(productNodeId, true);
    setNodeMaterial(productNodeId, matId);
    if (metalBaseColor !== 'White'){
      setMaterialBaseColor(matId, Colors[metalBaseColor]);
    }
  } else if (Model['Product'].indexOf('Modular Chandelier') !== -1) {
    const metalBaseColor = Model['Base Color'];
    let matName = `M_Ceiling Plate`;
    if ( metalBaseColor == 'Black'){
       matName = `M_Ceiling PlateBW`;
    }
    else if (metalBaseColor == 'White'){
      matName = `M_MetalBaseW`;
    }
    const matId = api.scene.find(matName);
    const productSize = Model['Product']
      .split('-')[1]
      .trim()
      .replace(/["]+/g, '');
  const productName = 'Modular - ' + productSize;
    const productNodeId = api.scene.find({ name: productName });
    setNodeVisibility(productNodeId, true);
    setNodeMaterial(productNodeId, matId);
    if (metalBaseColor !== 'White'){
      setMaterialBaseColor(matId, Colors[metalBaseColor]);
    }
  }
  else {
    setVisibility(api.scene.find('Wood Chandelier'), false, 'all');
    setVisibility(api.scene.find('Metal Chandelier'), false, 'all');
    setVisibility(api.scene.find('Modular Chandelier'), false, 'all');
  }
}
function updateUBracket() {
  api.scene.setAll(
    {
      from: { name: 'U_brackets_combined' },
      type: ['PolyMesh'],
      plug: 'Properties',
      property: 'visible',
    },
    false
  );
  if (Model['Product'].indexOf('Wrap Chandelier') !== -1) {
    const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS];
    setVisibility(api.scene.find(`U_bracket_${numOfPendants}`), true, 'all');
    //Pipe fitting mat
    if (Model['Hardware Finish'] === "White"){
      setMaterialReflection(
        api.scene.find('M_Pipe_Fitting'),
        .8,.2
      );
    }
    else {
      console.log("Setting the reflection to .2,1");
      setMaterialReflection(
        api.scene.find('M_Pipe_Fitting'),
        .2,1
      );
  }
  setMaterialBaseColor(
    api.scene.find('M_Pipe_Fitting'),
    Colors[Model['Hardware Finish']]
  );
    //bracket mat
    const bracketNullId = api.scene.find(`U_bracket_${numOfPendants}`);
    const matId = api.scene.find(`M_New_${Model['Wood Stain']}`);
    api.scene
      .filter({ from: bracketNullId, name: 'Pipe_pipe_*' })
      .forEach(pipeId => {
        setNodeMaterial(pipeId, matId);
      });
  }
}

function updateBeamWrap() {
  api.scene.setAll(
    {
      from: { name: 'Beam_combined' },
      type: ['PolyMesh'],
      plug: 'Properties',
      property: 'visible',
    },
    false
  );
  if (Model['Product'].indexOf('Beam Wrap') !== -1) {
    //fixes issue between wood and beam wrap
    if (Model['Wood Stain'] == 'White' || Model['Wood Stain'] == 'Natural'){
      Model['Wood Stain'] = "Ebony";
    }

    const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS];
    setVisibility(api.scene.find(`Beam_${numOfPendants}`), true, 'all');
    //beam mat
    const matId = api.scene.find(`M_Beam_${Model['Wood Stain']}`);
    setNodeMaterial(api.scene.find(`Beam_Base_${numOfPendants}`), matId);
    //bracket mat
    setMaterialBaseColor(
      api.scene.find('M_Beam_Chain'),
      Colors[Model['Chain Finish']]
    );
    setMaterialBaseColor(
      api.scene.find('M_Beam_Bracket'),
      Colors[Model['Bracket Finish']]
    );
    if ( Model['Cord Type'] !== 'Round'){
      Model['Cord Type'] = 'Round';
    }
  }



}
export { updateCeilingPlate, updateWallSconce, updateWoodChandelier, updateUBracket, updateBeamWrap };

import api from './claraplayer';


let loading = true;

const onChangeStart = () => {
  // Before change get started
};
const onChangeFinishing = async (attr) => {
 
  await api.sceneIO.whenLoaded();
  //>> Data ready for visual updates

  //<<

  //console.log(attr + " is finishing");
  
  setTimeout(onChangeFinished(attr), 0);
};
const onChangeFinished = (attr) => {
  //Your custom code below for after-all-updated
  let translation = {
    x: 0,
    y: 0.2,
    z: 1.5
};

let cameraId = api.player.getCamera();
api.scene.set({
    id: cameraId,
    plug: 'Transform',
    property: 'translation'
}, translation);
api.player.frameScene(undefined, {
  mode: 'geometry',
  padding: 0,
});

if (attr == "Product"){
  loading = false;
}

if (typeof testFunction == 'function' && !loading) {
  //console.log("Going to Test Function");
 testFunction(attr);
}
  // window.alert('Finished');
  //console.log('Finished');
};
const onLoadingStep = async (step) => {
  // Before change get started
  setTimeout(onLoadingStepFinished(step), 0);

};


const onLoadingStepFinished = (step) => {
  if (typeof loadingStepFunction == 'function') {
    //console.log("Going to Test Function");
   loadingStepFunction(step);
  }

};

const hoveringOn = (attr,x,y) => {
  console.log(attr + x + y);
  if (typeof localFunctionHover == 'function'){
  localFunctionHover(attr,x,y);
  }
};
export { onChangeStart, onChangeFinishing, onLoadingStep, hoveringOn };

import api from './claraplayer';

const HOST = 'https://hangout-lighting.mythreekit.com';

async function saveConfig() {
  const transit = api.sceneIO.writeScene((node, evalNode) => {
    if (node.type === 'Null' || node.type === 'PolyMesh') {
      const visible = api.scene.get({
        id: node.id,
        plug: 'Properties',
        property: 'visible',
      });
      return visible;
    }
    return true;
  });
  const form = new FormData();
  form.append('name', `publish-${new Date().getTime()}`);
  form.append('content', transit);
  form.append('type', 'application/vnd.clara.transit-v46+json');
  form.append('sceneId', api.sceneIO.getSceneId());
  try {
    const res = await fetch('https://clara.io/api/files', {
      method: 'POST',
      body: form,
    });
    const json = await res.json();
    return json.hash;
  } catch (e) {
    console.error(e);
  }
}

async function claraExport(
  interval = 5000,
  getUrl = false,
  filename = 'model'
) {
  const sceneId = api.sceneIO.getSceneId();
  try {
    const publishedHash = await saveConfig();
    // claraExport(sceneId, publishedHash);
    const res = await fetch(
      `${HOST}/export/clara?sceneId=${sceneId}&hash=${publishedHash}`,
      { method: 'POST' }
    );
    const json = await res.json();
    const location = json.location;
    const result = await new Promise(resolve => {
      const inter = setInterval(async () => {
        const res = await fetch(
          `${HOST}/export/result?hash=${publishedHash}&location=${location}`
        );
        const json = await res.json();
        if (json.status === 'ok' || json.status === 'failed') {
          resolve(json);
          clearInterval(inter);
        }
      }, interval);
    });
    if (result.status !== 'ok') {
      return console.error(result);
    }
    const fileUrl = result.fileUrl;
    if (getUrl) return fileUrl;
    const fileRes = await fetch(fileUrl);
    const file = await fileRes.blob();
    let blob = new Blob([file]);
    var fileName = `${filename}.zip`;
    if (window.navigator.msSaveOrOpenBlob) {
      //For download on IE
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const download = document.createElement('a');
      download.setAttribute('href', url);
      download.setAttribute('download', fileName);

      if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        download.dispatchEvent(event);
      } else {
        download.click();
      }
    }
  } catch (e) {
    console.error(e);
  }
}

async function threekitExport() {
  const sceneId = api.sceneIO.getSceneId();
  const publishedHash = await writeScene();
  return fetch(
    `${HOST}/export/threekit?sceneId=${sceneId}&hash=${publishedHash}`
  );
}

export { saveConfig, claraExport };

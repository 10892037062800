import api from './helpers/claraplayer';
import { Colors, ATTRNAME_NUMBER_PENDANTS } from './constants';
import { Model } from './Model';
import {
  setNodeVisibility,
  setMaterialBaseColor,
  setMaterialReflection,
} from './helpers/sceneHelpers';
import urlParams from './helpers/urlParams';
import {
  updateCeilingPlate,
  updateWallSconce,
  updateWoodChandelier,
  updateUBracket,
  updateBeamWrap,
} from './Views/baseViews';
import {
  updatePendantView,
  updateCeilingHook,
  updateTopGrip,
  updateRope,
  updateTwistedCordColor,
  updateRoundCordColor,
  updateThickCordType,
  updateChainColor,
  updateSocket,
  updateBottomGrip,
  updateCageShadeSwitch,
  updateCage,
  updateShade,
  updateShadeFitter,
  updateBulb,
  updateExtras,
  updateSocketStem,
  updateChainSize,
  updateShadeCover,
  updateRigidStem,
} from './Views/pendantView';
import { updatePendantNodes, pendantAttach } from './pendants';

const { isInternal } = urlParams;
function internalVerCallHandler(func, attr, newValue) {
  if (isInternal) {
    return func(attr, newValue);
  } else {
    const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
    const promises = [];
    for (let index = 0; index < numOfPendants; index++) {
      promises.push(func(index, newValue));
    }
    return Promise.all(promises);
  }
}
const updateProductView = async function() {
  // console.log('Rendering product base view');
  updateWoodChandelier();
  await updatePendantsView();
  await updateExtras();

};
const updatePendantsView = async function() {
  // console.log('Rendering product pendant views');
  updateUBracket();
  updateBeamWrap();
  if (Model['Product'].indexOf("Wall Sconce") !== -1) {
    updateWallSconce();
  }
  await updatePendantNodes();
   console.log('Nodes updated');
  updateCeilingPlate();
  const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
  const promises = [];
  for (let index = 0; index < numOfPendants; index++) {
    promises.push(updatePendantView(index));
  }
  await Promise.all(promises);
  for (let index = 0; index < numOfPendants; index++) {
    pendantAttach(index);
  }
};
function nodesReattach(attr) {
  // console.log('Reaaaaaaaaa');
  if (isNaN(attr)) {
    const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
    for (let i = 0; i < numOfPendants; i++) {
      pendantAttach(i);
    }
  } else {
    pendantAttach(attr);
  }
}
const View = {
  //Scale where attribute change could affects
  update: {
    Product: updateProductView,
    'Ceiling Plate': async function() {
      updateCeilingPlate();
      if (Model['Product'].charAt(0) !== 'W') {
        const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
        const promises = [];
        for (let i = 0; i < numOfPendants; i++) {
          promises.push(updateTopGrip(i));
        }
        await Promise.all(promises);
        nodesReattach();
      }
      if (Model['Product'].indexOf('Wood Chandelier') !== -1){
        const outgoingMatId = api.scene.find('M_Outgoing Wire');
        setMaterialBaseColor(outgoingMatId, Colors[Model['Ceiling Plate']]);
      }
      if (Model['Product'].indexOf('Beam Wrap') !== -1){
        if (Model['Cord Type'] === 'Round'){
          const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
          const cordType = Model["Cord Type"];
           const outgoingMatId = api.scene.find('M_Outgoing Wire');
          const beamTopNullName = `Wrap_Rope_${numOfPendants}_Base`;
          const beamTopNullId = api.scene.find(beamTopNullName);
          const beamTopNodeId = api.scene.find({
            from: beamTopNullId,
            name: `Outgoing_${cordType}_${numOfPendants}_Top`,
          });
          setMaterialBaseColor(outgoingMatId, Colors[Model['Ceiling Plate']]);
        }
      }
    },
    'Wood Stain': async function() {
      updateCeilingPlate();
      updateWoodChandelier();
      updateUBracket();
      updateBeamWrap();
      const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
      const promises = [];
      for (let i = 0; i < numOfPendants; i++) {
        promises.push(updateTopGrip(i));
      }
      await Promise.all(promises);
      nodesReattach();
    },    'Metal Base': async function() {
          updateCeilingPlate();
          updateWoodChandelier();
          const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
          const promises = [];
          for (let i = 0; i < numOfPendants; i++) {
            promises.push(updateTopGrip(i));
          }
          await Promise.all(promises);
          nodesReattach();
        },
    'Wood Type': function() {
      updateWoodChandelier();
    },
    'Base Color': function() {
      updateCeilingPlate();
      updateWoodChandelier();
    },
    'Outgoing Wire': function(attr, newValue) {
      let wireId = api.scene.find('Outgoing Wire');
      const matId = api.scene.find('M_Outgoing Wire');
      setMaterialBaseColor(matId, Colors[newValue]);
      setNodeVisibility(wireId, true);
    },
    'Hardware Finish': function(attr, newValue) {
      if (Model['Hardware Finish'] === "White"){
        setMaterialReflection(
          api.scene.find('M_Pipe_Fitting'),
          .8,.2
        );
      }
      else {
        setMaterialReflection(
          api.scene.find('M_Pipe_Fitting'),
          .2,1
        );
    }
      setMaterialBaseColor(api.scene.find('M_Pipe_Fitting'), Colors[newValue]);
    },
    'Bracket Finish': function(attr, newValue) {
      setMaterialBaseColor(api.scene.find('M_Beam_Bracket'), Colors[newValue]);
    },
    'Chain Finish': function(attr, newValue) {
      setMaterialBaseColor(api.scene.find('M_Beam_Chain'), Colors[newValue]);
    },
    'Arm Finish': function(attr, newValue) {
      updateWallSconce();
    },
    'Tee Joint Finish': function(attr, newValue) {
      updateWallSconce();
    },
    'Endcap Finish': function(attr, newValue) {
      updateWallSconce();
    },
    'Hinge Finish': function(attr, newValue) {
      updateWallSconce();
    },
    'Cluster Ring': function(attr, newValue) {
      setMaterialBaseColor(api.scene.find('M_Cluster Ring'), Colors[newValue]);
    },
    'Number of Pendants': updatePendantsView,
    ///////////////////////////////////////////////////////
    'Ceiling Hooks': async function(attr, newValue) {
      await internalVerCallHandler(updateCeilingHook, attr, newValue);
      nodesReattach(attr);
    },
    'Cord Type': async function(attr, newValue) {
      const promises = [
        internalVerCallHandler(updateCeilingPlate, attr, newValue),
        internalVerCallHandler(updateRope, attr, newValue),
        internalVerCallHandler(updateTopGrip, attr, newValue),
        internalVerCallHandler(updateBottomGrip, attr, newValue),
        
      ];
      await Promise.all(promises);
      if (Model['Product'].indexOf('Swag') !== -1) {
        await internalVerCallHandler(updateCeilingHook, attr, newValue);
      }
      nodesReattach(attr);
    },
    'Twisted Cord Color': async function(attr, newValue) {
      internalVerCallHandler(updateTwistedCordColor, attr, newValue);
      const promises = [
        internalVerCallHandler(updateRope, attr, newValue),
      ];
      await Promise.all(promises);
      nodesReattach(attr);
    },
    'Round Cord Color': async function(attr, newValue) {
      internalVerCallHandler(updateRoundCordColor, attr, newValue);
      const promises = [
        internalVerCallHandler(updateRope, attr, newValue),
      ];
      await Promise.all(promises);
      nodesReattach(attr);
    },    
    'Chain Color': async function(attr, newValue) {
      internalVerCallHandler(updateChainColor, attr, newValue);
      const promises = [
        internalVerCallHandler(updateRope, attr, newValue),
        internalVerCallHandler(updateTopGrip, attr, newValue),
        internalVerCallHandler(updateBottomGrip, attr, newValue),
      ];
      await Promise.all(promises);
      nodesReattach(attr);
    },
    'Rigid Stem Finish': async function(attr, newValue) {
      console.log("Doing shit");
      internalVerCallHandler(updateRigidStem, attr, newValue);
    },
    'Thick Cord Type': async function(attr, newValue) {
      internalVerCallHandler(updateThickCordType, attr, newValue);
      const promises = [
        internalVerCallHandler(updateRope, attr, newValue),
        internalVerCallHandler(updateTopGrip, attr, newValue),
        internalVerCallHandler(updateBottomGrip, attr, newValue),
      ];
      await Promise.all(promises);
      nodesReattach(attr);
    },
    'Chain Size': async function(attr, newValue) {
      internalVerCallHandler(updateChainSize, attr, newValue);
      const promises = [
        internalVerCallHandler(updateRope, attr, newValue),
        internalVerCallHandler(updateTopGrip, attr, newValue),
        internalVerCallHandler(updateBottomGrip, attr, newValue),
      ];
      await Promise.all(promises);
      if (Model['Product'].indexOf('Swag') !== -1) {
        await internalVerCallHandler(updateCeilingHook, attr, newValue);
      }
      nodesReattach(attr);
    },
    Socket: async function(attr, newValue) {
      await internalVerCallHandler(updateSocket, attr, newValue);
      await internalVerCallHandler(updateShadeFitter, attr, newValue);
      internalVerCallHandler(updateBottomGrip, attr, newValue);

      nodesReattach(attr);
      if (Model['Product'].indexOf('Cluster') === -1) {
        const attrName = isInternal
          ? `Cage / Shade - ${attr * 1 + 1}`
          : 'Cage / Shade';
        if (newValue.indexOf('Threaded') !== -1) {
          api.configuration.executeAttribute(
            attrName,
            Model[attrName] || 'Shade'
          );
        } else if (newValue.indexOf('Wood') !== -1) {
          api.configuration.executeAttribute(attrName, 'None');
        } else {
          api.configuration.executeAttribute(
            attrName,
            Model[attrName] || 'Cage'
          );
        }
      }
    },
    'Cage / Shade': async function(attr, newValue) {
      await internalVerCallHandler(updateCageShadeSwitch, attr, newValue);
      nodesReattach(attr);
      api.player.frameScene();
    },
    Cage: async function(attr, newValue) {
      await internalVerCallHandler(updateCage, attr, newValue);
      nodesReattach(attr);
    },
    Shade: async function(attr, newValue) {
      await internalVerCallHandler(updateShade, attr, newValue);
      await internalVerCallHandler(updateShadeFitter, attr, newValue);
      internalVerCallHandler(updateBottomGrip, attr, newValue);
      internalVerCallHandler(updateShadeCover, attr, newValue);
      nodesReattach(attr);
    },
    'Shade Fitter': async function(attr, newValue) {
      await internalVerCallHandler(updateShadeFitter, attr, newValue);
      nodesReattach(attr);
    },
    'Shade Cover': async function(attr, newValue) {
      await internalVerCallHandler(updateShadeCover, attr, newValue);
      nodesReattach(attr);
    },
    Bulb: async function(attr, newValue) {
      await internalVerCallHandler(updateBulb, attr, newValue);
      nodesReattach(attr);
    },
    'Fixture Length': async function(attr, newValue) {
      //Cluster - add by 3 inches
      await internalVerCallHandler(updateRope, attr, newValue);
      nodesReattach(attr);
      await updateExtras();
     // api.player.frameScene();
    },
    'Length(Feet)': async function(attr, newValue) {
      //Single
      await internalVerCallHandler(updateRope, attr, newValue);
      internalVerCallHandler(updateBottomGrip, attr, newValue);
      nodesReattach(attr);
      await updateExtras();
      //api.player.frameScene();
    },
    Length: async function(attr, newValue) {
      //Swag
      await internalVerCallHandler(updateRope, attr, newValue);
      nodesReattach(attr);
      await updateExtras();
      //api.player.frameScene();
    },

  'Extras': async function() {

      await updateExtras();

   //api.player.frameScene();
  },
  'Socket Stem': async function(attr, newValue) {

    await internalVerCallHandler(updateSocketStem, attr, newValue);
    internalVerCallHandler(updateBottomGrip, attr, newValue);
    nodesReattach(attr);
 //api.player.frameScene();
},
  },
  //Clear player
  clearProductView: function() {
    //Hide Base scene
    api.scene.setAll(
      {
        from: { name: 'Objects' },
        type: ['PolyMesh'],
        plug: 'Properties',
        property: 'visible',
      },
      false
    );
  },
};

export { View };

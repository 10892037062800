import api from './claraplayer';

/**
 * Set the material for node
 * @param {string} node_id ID of node
 * @param {string} matId id material
 */
function setNodeMaterial(node_id, matId) {
  api.scene.set(
    { id: node_id, plug: 'Material', property: 'reference' },
    matId
  );
}
/**
 * @description set visibility of node by value of bool
 * @param {string} node_id Id of node
 * @param {boolean} bool true or false
 */
function setNodeVisibility(node_id, bool) {
  api.scene.set(
    {
      id: node_id,
      plug: 'Properties',
      property: 'visible',
    },
    bool
  );
}
function getRotation(id) {
  return api.scene.get({
    id: id,
    plug: 'Transform',
    property: 'rotation',
  });
}
function setRotation(id, rotationArr) {
  api.scene.set(
    {
      id: id,
      plug: 'Transform',
      property: 'rotation',
    },
    rotationArr
  );
}
/**
 *
 * @param {string} id id
 * @param {Array} newTranslation [x,y,z]
 * @param {Object} translation {x,y,z}
 */
function setTranslation(id, newTranslation, translation = undefined) {
  if (translation) {
    api.scene.set(
      {
        id: id,
        plug: 'Transform',
        property: 'translation',
      },
      [
        translation.x + newTranslation[0],
        translation.y + newTranslation[1],
        translation.z + newTranslation[2],
      ]
    );
  } else {
    api.scene.set(
      {
        id: id,
        plug: 'Transform',
        property: 'translation',
      },
      newTranslation
    );
  }
}
/**
 *
 * @param {string} fromId Id of where search from
 * @param {boolean} bool
 * @param {string} part 'all' to setAll, otherwise provide nodename
 */
function setVisibility(fromId = undefined, bool, part) {
  const from = fromId === undefined ? {} : { id: fromId };
  part !== 'all'
    ? api.scene.set(
        {
          from: from,
          name: part,
          plug: 'Properties',
          property: 'visible',
        },
        bool
      )
    : api.scene.setAll(
        {
          from: from,
          plug: 'Properties',
          property: 'visible',
        },
        bool
      );
}
/**
 *
 * @param {string} matId ID of material node
 * @param {string} rgbValue RGB color value
 */
function setMaterialBaseColor(matId, rgbValue) {
  api.scene.set(
    {
      id: matId,
      plug: 'Material',
      property: 'baseColor',
    },
    rgbValue
  );
}
function setMaterialReflection(matId, r,m) {
  api.scene.set(
    {
      id: matId,
      plug: 'Material',
      property: 'roughness',
    },
    r
  );
  api.scene.set(
    {
      id: matId,
      plug: 'Material',
      property: 'metallic',
    },
    m
  );
}
export {
  setNodeMaterial,
  setNodeVisibility,
  getRotation,
  setRotation,
  setTranslation,
  setVisibility,
  setMaterialBaseColor,
  setMaterialReflection,
};
